import Skills from "./skills.js"
import Projects from "./projects.js"
import MyInterests from "./myInterests.js"
import { Element } from "react-scroll"

function Contents(){
  return(
    <div className='content'>
        <div className='white-cover'></div>
        <Element name='skills-link' className='element'>
            <Skills />
        </Element>
        <Element name='projects-link' className='element'>
            <Projects />
        </Element>
        <Element name='interests-link' className='element'>
          <MyInterests />
        </Element>
    </div>
  )
}

export default Contents