import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSafari, faGithubSquare } from '@fortawesome/free-brands-svg-icons'

function LaunchPad(){

  return(
    <section className='project-box launchPad'>
      <div className='title'>launch pad</div>
      <div className='image-launchpad'>
      </div>
      <div className='info'>
        LaunchPad is a React based app designed to provide up-to-date rocket launch information with a community voting feature used to verify user posted updates. The backend is written in Django with CRUD capability.
      </div>
      <div className='icons'>
        <a className='deployment-link' href='https://rocket-launch-pad.netlify.app/'><FontAwesomeIcon icon={faSafari}/></a>
        <a className='github-link' href='https://github.com/Felix-FE/P4-FE'><FontAwesomeIcon icon={faGithubSquare}/></a>
      </div>
    </section>
  )
}

export default LaunchPad  