import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithubSquare  } from '@fortawesome/free-brands-svg-icons'
import React from 'react'
import Ga from '../images/GALogo.png'
import Fan from '../images/Screenshot_20211027-145502__01_auto_x2.jpg'
import Ceph from '../images/cepha.png'

function AboutMe(){

  const [next, setNext] = React.useState(false)
  const [viewL, setViewL] = React.useState(false)
  const [viewR, setViewR] = React.useState(true)
  const [isReveal, setIsReveal] = React.useState({
    one: false,
    two: false,
    three: false,
  })

  function handleNext(e){
    console.log(e.target.className)
    if(e.target.className.includes('next-arrow')){
      setNext('rF')
      setViewL(true)
      setViewR(false)
    } else if(e.target.className.includes('prev-arrow')){
      setNext('lF')
      setViewL(false)
      setViewR(true)
    }
  }

  function handleReveal(e){
    console.log(e.target.className)
    if(e.target.className.includes('one')){
      if(isReveal.one === false){
        setIsReveal({...isReveal, one:true})
      }else if(isReveal.one === true){
        setIsReveal({...isReveal, one:false})
      }
    } else if(e.target.className.includes('two')){
      if(isReveal.two === false){
        setIsReveal({...isReveal, two:true})
      }else if(isReveal.two === true){
        setIsReveal({...isReveal, two:false})
      }
    } else if(e.target.className.includes('three')){
      if(isReveal.three === false){
        setIsReveal({...isReveal, three:true})
      }else if(isReveal.three === true){
        setIsReveal({...isReveal, three:false})
      }
    }
  }

  return(
    <>
    <section className='about-me'>
      <div className={`next-arrow ${viewR}`} onClick={handleNext}></div>
      <div className={`prev-arrow ${viewL}`} onClick={handleNext}></div>
      <div className={`slide-wrapper ${next}`}>
        <div className={`intro-wrapper`}>
          <div className='welcome-wrapper'>
            <div className='me-wrapper'>
              <div className='rgb-circle'>
                <div>Hi </div> 
              </div>
              <div className='fixed-container'>
                <div className='animate-container'>
                    <div className='big-text intro'>I'm Felix Fenn-Evans
                    </div>
                </div>
              </div>
            </div>  
            <div className='big-text welcome'>junior full-stack developer specialising in React with Django and Express</div>
            <div className='timeline wrapper'>
              <div className={`time-line ${next}`}>
                <div className='circle'></div>
              </div>
            </div>
          </div>  
          <div className='small-text bio'> I am a lifelong designer and problem solver who is driven by creating. Originally a Product Designer, I have transfered my strengths in developing products and finding innnovative solutions to the software development eco-system. Through self-learning and a bootcamp, my drive for learning is fuelled by the array of diverse technologies, I as a developer, can implement in my projects. </div>
          <div className='small-text links'>
            <a href='https://github.com/Felix-FE' className='github'>GitHub <FontAwesomeIcon icon={faGithubSquare} /></a>
            <a href='https://www.linkedin.com/in/felixfennevans/' className='linkedIn'>LinkedIn <FontAwesomeIcon icon={faLinkedin} /></a>
          </div>
          <div className='scroll-down'>scroll down</div>
        </div>
        <div  className='my-story'>
          <div className='title'>my journey</div>
          <div className='story-top'>
            <div className={`stage experimenting ${next}` } >
              <div style={{backgroundImage: `url(${Fan})`}} className={`info one`} onMouseEnter={handleReveal}>
                <div className={`text one${isReveal.one}`}> 
                  <h1 className='date'> 2018 </h1>
                  <h1 className='description'>I started my journey into software development as a designer. In hindsight there were a lot of similarities. Product design is based on solving real world problems with physical objects. However, in my final year project i decided to learn arduino to create a sensor controlled standing fan. This sparked my interest in solving problems via coding.</h1>
                </div>
              </div>
              <div className='pin'></div>
            </div>
            <div className={`stage designer ${next}`}>
              <div style={{backgroundImage: `url(${Ceph})`}} className={`info three`} onMouseEnter={handleReveal}>
                <div className={`text three${isReveal.three}`}> 
                  <h1 className='date'> 2020 </h1>
                  <h1 className='description'>After spending a couple of years freelancing as a designer. I felt I could better apply my knack for problem solving in software. My experience in analysing project briefs as well as interacting with customers to better understand their needs, I believe I could carry these skills into the development eco-system.</h1>
                </div>
              </div>
              <div className='pin'></div>
            </div>
            <div className={`stage developer ${next}`}>
              <div style={{backgroundImage: `url(${Ga})`}} className={`info two`} onMouseEnter={handleReveal}>
                <div className={`text two${isReveal.two}`}>
                  <h1 className='date'> 2021 </h1>
                  <h1 className='description'>This is when I decided to start self-learning python. However, I noticed I was missing a bigger picture view of the software ecosystem. This is when I decided to join General Assembly as a student. This provided me with the understanding of production ready tools to build my knowledge and gain a confidence in software development.</h1>
                </div>
              </div>
              <div className='pin'></div>  
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default AboutMe