import './App.css';
import AboutMe from './components/aboutMe.js'
// import Projects from './components/projects';
// import Skills from './components/skills';
import React from 'react';
import { InView } from 'react-intersection-observer'
import Nav from './components/nav';
import { Element } from 'react-scroll'
// import MyInterests from './components/myInterests';
// import Footer from './components/footer';
import Contents from './components/content';
import Footer from './components/footer';


function App() {
  const [aboutInView, setAboutInView] = React.useState(false)

  return (

    <> 
      <Nav AboutInView={aboutInView} />
      <Element name='about-link' className='element aboot'>
        <InView onChange={setAboutInView} threshold='0.6'>
          <AboutMe />
        </InView>
      </Element>
      <Contents />
      <Footer />
    </>  
  );
}

export default App;
