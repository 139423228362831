import React from "react"
import Battleships from "../Projects.js/battleships"
import LaunchPad from "../Projects.js/launchpad"
import MyNightIn from "../Projects.js/mynightin"
import Picturest from "../Projects.js/picturest"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faProjectDiagram, faMousePointer } from '@fortawesome/free-solid-svg-icons'

function Projects(){
  const [sL, setSL] = React.useState(true)
  const [sR, setSR] = React.useState(false)

  function handleScroll(e){
    console.log(e.target.className)
    if(e.target.className.includes('scroll-trigger left')){
      setSR(false)
      setSL(true)
    }else if(e.target.className.includes('scroll-trigger right')){
      setSL(false)
      setSR(true)
    }
  }


  return(
    <section className='projects-wrapper'>
      <div className='label'>
        <div className='icon'>
          <FontAwesomeIcon icon={faProjectDiagram} />
        </div>
        <div className='hover'>
          <div className='box'></div>
          <div className='label-mini'></div>
          <div className='pointer'>
            <FontAwesomeIcon icon={faMousePointer}/>
          </div>
        </div>
      </div>
      <div className='projects'>
        <div className={`scroll-trigger left ${sL}`} onMouseEnter={handleScroll}  ></div>
        <div className={`scroll-trigger right ${sR}`} onMouseEnter={handleScroll} ></div>
        <div className='title'>projects</div>
        <div className='overflow-wrap'>
          <div className={`border-projects left-${sL} right-${sR}`}>
            <LaunchPad />
            <Picturest />
            <MyNightIn />
            <Battleships />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Projects