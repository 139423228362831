import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
function Footer(){

  return(
    <footer className='footer-wrapper'>
      <div className='left-footer'>
        <a href= 'mailto: felixfennevans@gmail.com' >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </div>
      <div className='right-footer'>
      </div>
    </footer>
  )
}

export default Footer