import { Npm, SolidWorks, Fusion, Ps, Ai, Pp, Javascript, Python, MongoDB, Express, PostgreSQL, Css, Html, Django, ReactIcon, Vscode, Git, Node, Sass} from '../icons/icons.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons'

function Skills(){

  return(
    <div className={`border-wrapper`}>
      <div className='label'>
        <div className='icon'>
          <FontAwesomeIcon icon={faLaptopCode} />
        </div>
      </div>
      <section className='skills'>
        <h1>Dev Software</h1>
        <div className='icons-wrapper'>
          <div className='icon'>
            <Javascript />
            <h1>JavaScript</h1>
          </div>
          <div className='icon'>
            <Python />
            <h1>Python</h1>
          </div>
          <div className='icon'>
            <ReactIcon /> 
            <h1>React</h1>
          </div>
          <div className='icon'>
            <Css />
            <h1>CSS</h1>
          </div>
          <div className='icon'>
            <Html />
            <h1>HTML</h1>
          </div>
          <div className='icon'>
            <Express />
            <h1>Express</h1>
          </div>
          <div className='icon'>
            <MongoDB />
            <h1>MongoDB</h1>
          </div>
          <div className='icon'>
            <Django />
            <h1>Django</h1>
          </div>
          <div className='icon'>
            <PostgreSQL />
            <h1>PostgreSQL</h1>
          </div>
          <div className='icon'>
            <Node />
            <h1>Node.js</h1>
          </div>  
          <div className='icon'>
            <Sass />
            <h1>Sass</h1>
          </div>
          <div className='icon'>
            <Git />
            <h1>Git</h1>
          </div>
          <div className='icon'>
            <Vscode />
            <h1>Vscode</h1>
          </div>
          <div className='icon'>
            <Npm />
            <h1>npm</h1>
          </div>
        </div>
        <div className='separating-line'></div>
        <h1 className='other'>Other Software</h1>
        <div className='other-software'>
          <div className='icon'>
            <Ps />
            <h1>Photoshop</h1>
          </div>
          <div className='icon'>
            <Ai />
            <h1>Illustrator</h1>
          </div>
          <div className='icon'>
            <Pp />
            <h1>PremierPro</h1>
          </div>
          <div className='icon'>
            <Fusion />
            <h1>Fusion360</h1>
          </div>
          <div className='icon'>
            <SolidWorks />
            <h1>SolidWorks</h1>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Skills