import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSafari, faGithubSquare } from '@fortawesome/free-brands-svg-icons'

function Picturest(){

  return(
    <section className='project-box picturest'>
      <div className='title'>picturest</div>
      <div className='image-picturest'>
      </div>
      <div className='info'> Picturest is a React based app inspired by pinterest. It allows users to upload and geotag pictures. This allows photographers to find new photography opportunities. The app has a follow function as well as the ability to create a favourites collection. The back-end is built with express with a React front-end.</div>
      <div className='icons'>
        <a className='deployment-link' href='https://picturest-photography-app.netlify.app/'><FontAwesomeIcon icon={faSafari}/></a>
        <a className='github-link' href='https://github.com/Felix-FE/project-three-frontend'><FontAwesomeIcon icon={faGithubSquare}/></a>
      </div>
    </section>
  )
}

export default Picturest