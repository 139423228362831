import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSafari, faGithubSquare } from '@fortawesome/free-brands-svg-icons'

function MyNightIn(){

  return(
    <section className='project-box mynightin'>
      <div className='title'>my night in</div>
      <div className='image-mynightin'>
      </div>
      <div className='info'> My Night In is a concept marketing tool designed around the PUNK brewery api and themealdb api to generate random selections of beer and food. Users are able to filter their selections by ABV and Cuisine as well as being able to save their selection. </div>
      <div className='icons'>
        <a className='deployment-link' href='https://my-night-in.netlify.app/'><FontAwesomeIcon icon={faSafari}/></a>
        <a className='github-link' href='https://github.com/Felix-FE/sei-project-two-1'><FontAwesomeIcon icon={faGithubSquare}/></a>
      </div>
    </section>
  )
}

export default MyNightIn