import React from 'react'
import { Link } from 'react-scroll'

function Nav(props){
  
  const About = props.AboutInView
  const [visible, setVisible] = React.useState(false)

  function handleNav(){
    if(visible === true){
      setVisible(false)
    }else{
      setVisible(true)
    }
    
  }

  return(
    <nav className={`navi-${About}`}>
      <div className='left-nav'>
        <h1>felix.fennevans</h1>
      </div>
      <div className='view-port'>
        <div className='right-nav' onMouseEnter={handleNav} onMouseLeave={handleNav}>
          <div className='button navigation'>menu</div>
          <Link activeClass="active" className={`about-link ${visible}`} to="about-link" spy={true} smooth={true} duration={500} offset={0}>
            <div className='button about'>about</div>
          </Link>
          <Link activeClass="active" className={`skills-link ${visible}`} to="skills-link" spy={true} smooth={true} duration={500} offset={-150}>
            <div className='button skill'>skills</div>
          </Link>
          <Link activeClass="active" className={`projects-link ${visible}`} to="projects-link" spy={true} smooth={true} duration={500} offset={-150}>
            <div className='button project'>projects</div>
          </Link>
          <Link activeClass="active" className={`interests-link ${visible}`} to="interests-link" spy={true} smooth={true} duration={500} offset={-150}>
            <div className='button interest'>interests</div>
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default Nav