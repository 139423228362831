import { ROS, Jest, Three } from '../icons/icons.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle, faMousePointer } from '@fortawesome/free-solid-svg-icons'
import arduino from '../images/arduino.gif'
import twothousand from '../images/2001.jpg'
import mclaren from '../images/daniel-ricciardo-mclaren-mcl35.jpg'
import React from 'react'

function MyInterests(){
  const [sL, setSL] = React.useState(false)
  const [sR, setSR] = React.useState(false)

  function handleScroll(e){
    console.log(e.target.className)
    if(e.target.className === 'scroll-trigger left'){
      setSR(false)
      setSL(true)
    }else if(e.target.className === 'scroll-trigger right'){
      setSL(false)
      setSR(true)
    }
  }

  return(
    <section className='my-interests'>
      <div className='label'>
        <div className='icon'>
          <FontAwesomeIcon icon={faQuestionCircle} />
        </div>
        <div className='hover'>
          <div className='box'></div>
          <div className='label-mini'></div>
          <div className='pointer'>
            <FontAwesomeIcon icon={faMousePointer}/>
          </div>
        </div>
      </div>
      <div className='interests'>
        <div className='scroll-trigger left' onMouseEnter={handleScroll}></div>
        <div className='scroll-trigger right' onMouseEnter={handleScroll}></div>
        <div className='overflow-wrap'>
          <div className={`border-interests left-${sL} right-${sR}`}>
          <div className='line'></div>
            <div className='section hobbies'>
              <h1>hobbies</h1>
              <div className='list'>
                  <div><img src={arduino} alt='arduino'/><p>🔌</p><strong>Arduino</strong> Always looking to experimenting with components to create useful tech. My next project is an RC controlled buggy for my old dog.</div>
                  <div><img src={mclaren} alt='arduino'/><p>🏎</p><strong>Formula 1</strong> Every sunday you can find me watching the latest GP supporting my local racing outfit McLaren, I am inspired by the technical aspect of the cars, especially the problem solving when it comes to creating faster cars within regulations.</div>
                  <div><img src={twothousand} alt='arduino'/><p>🚀</p><strong>Science Fiction</strong> If its a science fiction film, I've definitely seen it. I take a lot of my inspiration from fictional futuristic concepts. 2001 and BladeRunner for me, are a significant influence.  </div>
              </div>
            </div>
            <div className='section currently-learning'>
              <h1>currently learning</h1>
              <div className='list'>
                  <div><Jest/><h1>Unittest (python)/ Jest (Javascript)</h1><h2>Im looking to create a project that involves testing throughout. This will improve my web app reliability and my development pipeline efficiency.</h2></div>
                  <div><Three /><h1>Three.js using React Three Fiber</h1><h2>As a designer, I want to develop my webGL skills to enahnce my web app experiences through adding interactivity and visual flare </h2></div>
                  <div><ROS/> <h1>ROS2 </h1><h2>As my coding journey was spured by electronics and to some extent robotics. I want to develop a more advanced understanding of how these robotic technologies will be intertwined with web apps in the future.</h2></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MyInterests