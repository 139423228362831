import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSafari, faGithubSquare } from '@fortawesome/free-brands-svg-icons'

function Battleships(){

  return(
    <section className='project-box battleships'>
      <div className='title'>battleships</div>
      <div className='image-battleships'>
      </div>
      <div className='info'>Battleships is a javascript version of the classic game battleships. I used Pure javascript to create a computer player which can intuitively find and destroy player ships. It is styled with HTML and CSS and uses DOM elements to create responsive gameplay elements.</div>
      <div className='icons'>
        <a className='deployment-link' href='https://felix-fe.github.io/sei57-webgame/'><FontAwesomeIcon icon={faSafari}/></a>
        <a className='github-link' href='https://github.com/Felix-FE/sei57-webgame/'><FontAwesomeIcon icon={faGithubSquare}/></a>
      </div>
    </section>
  )
}

export default Battleships